 /*
[ 29. Agents ]
*/

 .agents-wrapper{
   margin: 20px 0 0 0;


   .agent-show-box{
     background: #fff;
     padding-top: 15px;
     border: 1px solid #eee;
   }
   .agent-info-box{
     float:left;


     color: #fff !important;

     &:hover{
       z-index: 3;
       box-shadow: 0 70px 70px rgba(0,0,0,.2);
       -webkit-transform: scale(1.1);
       transform: scale(1.1);
       -webkit-transform: translate(0,-10px);
       transform: translate(0,-10px);
     }

     .agent-info-box-title, .agent-info-phone, a, .agent-info-email, .agent-info-box-social i{
       //color: #fff !important;
     }
     .agent-info-box-image {
      margin: 0 auto;
       text-align:center;
       display: block;
       img {
         //border-radius: 100%;
         max-width: 180px;
         margin: 0 auto;
         border: 2px solid #fff;
       }
     }
   }
 }

 .agent-small{
   display: none;
 }


 .agent-info-box{
   width: 100%;
   max-width: 270px;
  position: relative;
   -webkit-transition: all 0.6s ease 0s;
   -o-transition: all 0.6s ease 0s;
   transition: all 0.6s ease 0s;
   padding: 20px 5px 10px 5px;

  background: #fff;
   .agent-info-box-image{
     img{
       width: 100%;
     }
   }

   .agent-info-box-content{
     display: inline-block;
     width: 100%;
    text-align:center;

     .agent-info-box-content-inner{
       padding: 10px;
      // background: #fff;
      // background: #EDF1F2;
     }
     .agent-info-box-social{
       margin-top: 5px;
       padding: 10px;
       //background: #fff;
       //background: #EDF1F2;
     }

     .agent-info-phone{
       font-size: 18px;
       color: #777777;
     }

     .agent-info-email{
       font-size: 13px;
       //color: #666;
       //text-transform: uppercase;
     }


     h2{
       color: @brand-color !important;
       font-size: 16px;
       margin: 5px 0 15px 0;
      // text-transform: uppercase;
       font-weight: 500;
       &:after{
         display:none;
       }
     }

     ul{
       list-style:none;
       padding:0;
       margin:0;
       li{
         margin:0;
         padding:0;
       }
     }
   }
 }

.white-agent .agent-info-box .agent-info-box-content-inner,
.white-agent .agent-info-box .agent-info-box-social{
  background: #fff;
}

.agent-card {
  margin-bottom: -30px; }
.agent-card h2 {
  margin-top: 0px; }

.agent-card-info {
  color: #616161; }
.agent-card-info ul {
  list-style: none;
  margin: 0px;
  padding: 0px; }
.agent-card-info ul li {
  margin: 0px; }
.agent-card-info ul a {
//  color: #EC407A;
}
.agent-card-info ul .fa {
  margin-right: 5px; }

.agent-card-image {
  background-color: rgba(0, 0, 0, 0.15);
  display: block;
  height: 0px;
  padding-bottom: 100%;
  position: relative; }
.agent-card-image:before {
  color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  font-family: 'FontAwesome';
  font-size: 50px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%), translateY(-50%); }
.footer .agent-card-image:before {
  color: #42A5F5; }
.agent-card-image img {
  display: block;
  position: relative;
  width: 100%;
  z-index: 2; }

.agent-card-form .form-group:last-child {
  margin-bottom: 0px; }

.agent-small {
  margin: 0px 0px 15px 0px; }
.agent-small:last-child {
  margin-bottom: 0px;
  padding-bottom: 0px; }
.agent-small:last-child .agent-small-content {
  border-bottom: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px; }

.agent-small-inner {
  display: table;
  width: 100%; }

.agent-small-image {
  display: table-cell;
  width: 76px; }
.agent-small-image img {
  border-radius: 50%;
  position: relative;
  width: 100%;
  z-index: 2; }

.agent-small-image-inner {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  height: 50px;
  float: left;
  margin: 0px 16px 0px 0px;
  position: relative;
  width: 50px; }
.agent-small-image-inner:before {
  color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  font-family: 'FontAwesome';
  font-size: 18px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 0; }
.footer .agent-small-image-inner:before {
  color: #42A5F5; }

.agent-small-content {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: table-cell;
  padding-bottom: 15px;
  vertical-align: top; }
.footer .agent-small-content {
  border-bottom-color: rgba(255, 255, 255, 0.12); }

.agent-small-title {
  margin: 0px 0px 10px 0px; }
.agent-small-title a {
  color: rgba(0, 0, 0, 0.7);
  font-size: 15px;
  font-weight: 500; }
.footer .agent-small-title a {
  color: #fff; }

.agent-small-email, .agent-small-phone {
  font-size: 12px;
  line-height: 22px; }
.agent-small-email .fa, .agent-small-phone .fa {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  margin: 0px 6px 0px 0px;
  vertical-align: middle; }
.footer .agent-small-email .fa, .footer .agent-small-phone .fa {
  color: rgba(255, 255, 255, 0.7); }
.agent-small-email a:hover, .agent-small-phone a:hover {
  text-decoration: none; }
.footer .agent-small-email a, .footer .agent-small-phone a {
  color: #fff; }

.agent-simple {
  margin-bottom: 30px; }

.agent-simple-title {
  color: #616161;
  font-size: 20px; }
.agent-simple-title a {
  color: #616161; }
.agent-simple-title a:hover {
  text-decoration: none; }

.agent-simple-image {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  display: block;
  height: 0px;
  margin: 0px 0px 30px 0px;
  padding-bottom: 100%;
  position: relative; }
.agent-simple-image:before {
  color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  font-family: 'FontAwesome';
  font-size: 55px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%), translateY(-50%); }
.footer .agent-simple-image:before {
  color: #42A5F5; }
.agent-simple-image img {
  border-radius: 50%;
  position: relative;
  width: 100%;
  z-index: 2; }

.agent-simple-content {
  bottom: 45px;
  left: 0px;
  position: absolute;
  z-index: 3; }
.agent-simple-content span {
  background-color: rgba(255, 255, 255, 0.8);
  display: inline-block;
  line-height: 2;
  padding: 0px 8px; }
.agent-simple-content span .fa {
  margin-right: 5px; }
.agent-simple-content .agent-simple-email {
  margin: -3px 0px; }
.agent-simple-content .agent-simple-email a {
//  color: #EC407A;
}

.agent-row {
  background-color: #F5F5F5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  margin-bottom: 30px; }
@media (max-width: 767px) {
  .agent-row {
    background-color: #fff; } }
.agent-row .row, .agent-row .property-amenities ul, .property-amenities .agent-row ul {
  display: table;
  margin-left: 0px;
  margin-right: 0px;
  width: 100%; }
.agent-row .row > div, .agent-row .property-amenities ul > div, .property-amenities .agent-row ul > div {
  display: table-cell;
  float: none;
  vertical-align: top; }
@media (max-width: 767px) {
  .agent-row .row > div, .agent-row .property-amenities ul > div, .property-amenities .agent-row ul > div {
    display: block; } }

.agent-row-image {
  background-color: #fff;
  padding: 16px; }
.agent-row-image img {
  border-radius: 50%;
  position: relative;
  width: 100%;
  z-index: 2; }

.agent-row-image-inner a {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  display: block;
  height: 0px;
  padding-bottom: 100%;
  position: relative; }
.agent-row-image-inner a:before {
  color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  font-family: 'FontAwesome';
  font-size: 50px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%), translateY(-50%); }
.footer .agent-row-image-inner a:before {
  color: #42A5F5; }

.agent-row-content {
  background-color: #fff; }
.agent-row-content p {
  color: #616161; }

.agent-row-title a {
  color: #424242; }
.agent-row-title a:hover {
  text-decoration: none; }

.agent-row-subtitle {
  color: #616161;
  font-size: 15px; }

.agent-row-info {
  padding: 16px; }
.agent-row-info ul {
  color: #424242;
  list-style: none;
  margin: 0px;
  padding: 0px; }
.agent-row-info ul .fa {
  color: #616161;
  margin: 0px 5px 0px 0px; }
.agent-row-info ul li {
  margin: 0px; }
.agent-row-info ul a {
//  color: #EC407A;
}

.agent-medium {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  margin-bottom: 30px;
  padding: 16px; }

.agent-medium-image {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  display: block;
  float: left;
  height: 120px;
  position: relative;
  width: 120px; }
.agent-medium-image:before {
  color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  font-family: 'FontAwesome';
  font-size: 40px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 0; }
.footer .agent-medium-image:before {
  color: #42A5F5; }
.agent-medium-image img {
  border-radius: 50%;
  position: relative;
  width: 100%;
  z-index: 2; }

.agent-medium-content {
  color: #616161;
  margin: 0px 0px 0px 140px; }
.agent-medium-content ul {
  list-style: none;
  margin: 0px;
  padding: 0px; }
.agent-medium-content ul .fa {
  margin: 0px 5px 0px 0px; }
.agent-medium-content ul li {
  margin: 0px; }
.agent-medium-content a {
//  color: #EC407A;
}
.agent-medium-content hr {
  margin: 10px 0px; }

.agent-medium-title {
  margin: 0px; }
.agent-medium-title a {
  color: #424242; }
.agent-medium-title a:hover {
  text-decoration: none; }

.agent-medium-subtitle {
  color: #616161;
  font-size: 13px; }


 .agents-wrapper.agentslist{
  .agent-info-box{
    float:none;
    width: 100%;
    max-width: none;


    .agent-info-box-content-inner, .agent-info-box-social{
      text-align:left;
    }
  }
}

 .agent-show-box-title{
   text-align: left;
 }

 .agent-show-box-content{
   ul, li{
     list-style: none;
   }

   .agent-show-box-description{
     margin-top: 20px;
   }
 }

 .agent-info-box-social, .agent-show-box-social{
   .fa{
     font-size: 18px;
     display: inline-block;
     //background: @brand-color;
     border: 1px solid #BABABA;
     color: #BABABA;
     border-radius: 50%;
     width: 30px;
     height: 30px;
     line-height: 30px;
     text-align: center;

     &:hover{
       background: @brand-color;
       color: #fff;
       border:1px solid @brand-color;
     }
   }
 }

 .agent-show-latestproperties{
   margin-top: 50px;
 }

 .top-agents {
   h2{
     text-align:center;
     font-size:30px;
     display:block;
   }

   .agent-info-box{
     background: none;
     .agent-info-box-title{
       color: #fff !important;
       font-size:22px;
     }
     .agent-info-box-image img{
       border-radius: 50%;
       overflow: hidden;
       border:none;
     }
     .agent-info-box-subtitle{
       display:none;
     }
     .agent-info-phone{
       color:#fff !important;
     }
   }
   .agent-info-box-social{
     display:none;
   }
 }